/////////////////////////////////////////////
//** Variables **//
//** This is the file for basic overrides of bootstraps variables. **//
//** You can accomplish a lot of the site setup here. If you need access to more, checkout bootstrap_components/bootstrap/ and find the variables file. **//
/////////////////////////////////////////////

/////////////////////////////////////////////
//** Table of Contents **//
// Grid Settings
// Body
// Links
// Fonts
// Page Builder Settings
/////////////////////////////////////////////
//
// Color system
//

$white:  #fff;
$gray: #636466;
$gray-alt: #F3F3F3;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$grays: (
        100: $gray-100,
        200: $gray-200,
        300: $gray-300,
        400: $gray-400,
        500: $gray-500,
        600: $gray-600,
        700: $gray-700,
        800: $gray-800,
        900: $gray-900
) !default;

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #F26522;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: (
        blue: $blue,
        indigo: $indigo,
        purple: $purple,
        pink: $pink,
        red: $red,
        orange: $orange,
        yellow: $yellow,
        green: $green,
        teal: $teal,
        cyan: $cyan,
        white: $white,
        gray: $gray,
        gray-dark: $gray-800
) !default;

$theme-colors: (
        primary: $orange,
        secondary: $gray,
        tertiary: $gray-alt,
        success: $orange,
        info: $gray,
        warning: $yellow,
        danger: $red,
        light: $gray-100,
        dark: $gray-800
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

//** Grid settings **//
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
/////////////////////////////////////////////
//** Body **//
//** Settings for the `<body>` element. **//

//$body-bg:       $white !default;
$body-color:    $gray;
/////////////////////////////////////////////
//** Links **//
//** Style anchor elements. **//
//$link-color:            theme-color("primary") !default;
//$link-decoration:       none !default;
//$link-hover-color:      darken($link-color, 15%) !default;
//$link-hover-decoration: underline !default;
/////////////////////////////////////////////
//** Fonts **//
//** Font, line-height, and color for body text, headings, and more. **//
/////////////////////////////////////////////
//** FONT FAMILIES **//
$font-family-open-sans: 'Open Sans', sans-serif;
//$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
//$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-open-sans;
/////////////////////////////////////////////
//** FONT SIZES **//
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem;
$font-size-sm:   .875rem;
$h1-large: 4rem;
$h1-font-size: 3rem;
$h2-font-size: 2.25rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;
/////////////////////////////////////////////
//** FONT MARGINS **//
$h1-margin-bottom: 1rem; // 16px
$h2-margin-bottom: 0.875rem; // 14px
$h3-margin-bottom: 0.75rem; // 12px
$h4-margin-bottom: 0.6875rem; // 11px
$h5-margin-bottom: 0.625rem; // 10px
$h6-margin-bottom: .5rem; // 8px
//** FONT WEIGHTS **/
$font-weight-normal: normal;
//$font-weight-bold: bold !default;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.875;
/////////////////////////////////////////////
//** HEADINGS **//
//$headings-margin-bottom: ($spacer / 2) ];
$headings-font-family:   $font-family-open-sans;
$headings-font-weight:   100;
//$headings-line-height:   1.1 !default;
$headings-color:         map-get($theme-colors, primary);
/////////////////////////////////////////////
/////////////////////////////////////////////
//** GENERAL CONTENT **//
$paragraphs-margin-top: 0 !default;
$paragraphs-margin-bottom: 1rem !default;
$paragraphs-font-family:   inherit !default;
$paragraphs-font-weight:   normal !default;
$paragraphs-line-height:   $line-height-base;
$paragraphs-color:         map-get($theme-colors, secondary);
/////////////////////////////////////////////

//** PAGE BUILDER SETTINGS **//
$page-builder-block-spacing: 60px;
/////////////////////////////////////////////


