@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin scrimGradient($startColor: $color-black, $direction: 'to bottom') {

  $scrimCoordinates: (
          0: 1,
          19: 0.738,
          34: 0.541,
          47: 0.382,
          56.5: 0.278,
          65: 0.194,
          73: 0.126,
          80.2: 0.075,
          86.1: 0.042,
          91: 0.021,
          95.2: 0.008,
          98.2: 0.002,
          100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin animation ($delay, $duration, $timing, $animation) {
  -wekit-animation-timing-function: $timing;
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-timing-function: $timing;
  -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-timing-function: $timing;
  -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-timing-function: $timing;
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}
