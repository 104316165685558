.image-square {
  position: relative;
  height: 0;
  width: 100%;
  background-color: #eee;
  padding-bottom: 100%;
}

.image-rectangle {
  position: relative;
  height: 0;
  width: 100%;
  background-color: #eee;
  padding-bottom: 125%;

  &-small {
    position: relative;
    height: 0;
    width: 100%;
    background-color: #eee;
    padding-bottom: 56.25%;

    &.single-project--background {
      padding-bottom: 95%;

      @include media-breakpoint-up(sm) {
        padding-bottom: 65%;
      }

      @include media-breakpoint-up(md) {
        padding-bottom: 56.25%;
      }
    }

    &.single-news-post-feature--image {
      @include media-breakpoint-up(md) {
        padding-bottom: 65%;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 75%;
      }
    }

    @media only screen and (min-width: 1500px) {
      padding-bottom: 45%;
    }
  }
}
