.background-image
{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.bg-position-top {
    background-position: top;
  }

  &.bg-position-middle {
    background-position: middle;
  }

  &.bg-position-bottom {
    background-position: bottom;
  }
}

.scvl-hover-primary {
  &:hover {
    color: map-get($theme-colors, primary) !important;
    text-decoration: none;
  }
}

.scvl-background-shadow {
  box-shadow: 5px 5px 20px rgba(0,0,0,.2);

  @include media-breakpoint-up(md) {
    box-shadow: 15px 15px 30px rgba(0,0,0,.2);
  }
}

//.architecture-block-image {
//  box-shadow: 15px 15px 100px rgba(0,0,0,.2);
//}