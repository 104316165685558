.nav-primary {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  transform: translate3d(-285px, 0, 0);
  width: 285px;
  background: #fff;
  //background: $theme-dark-blue;
  z-index: 99999999999;
  overflow-x: hidden;
  overflow-y: auto;
  //display: none;
  opacity: 1;
  -webkit-overflow-scrolling: touch;
  background: #fff;

  box-shadow: 3px 0 6px 0 rgba(0,0,0,0.3);
  padding: 10px 20px;

  @include animation(0, 0.25s, linear, slideClosed);
  transform: translate3d(-285px, 0, 0);
  width: 285px;

  @media only screen and (min-width: 768px) {
    @include animation(0, 0.25s, linear, slideClosedWide);
    transform: translate3d(-485px, 0, 0);
    width: 485px;
  }
}

.nav-primary-menu--wrapper {
  height: auto;
  width: 230px;
  margin: 0 auto;

  .nav-primary-menu-logo {
    max-width: 160px;
  }
}

@include keyframes(slideOpen) {
  from {
    transform: translate3d(-285px, 0, 0);
    //@include transform(translate3d(0px, 0px, 0px));
  }

  to {
    transform: translate3d(0, 0, 0);
    //@include transform(translate3d(285px, 0px, 0px));
  }
}

@include keyframes(slideOpenWide) {
  from {
    transform: translate3d(-485px, 0, 0);
    //@include transform(translate3d(0px, 0px, 0px));
  }

  to {
    transform: translate3d(0, 0, 0);
    //@include transform(translate3d(285px, 0px, 0px));
  }
}

@include keyframes(slideClosed) {
  from {
    //@include transform(translate3d(285px, 0px, 0px));
    transform: translate3d(0, 0, 0);
  }

  to {
    //@include transform(translate3d(0px, 0px, 0px));
    transform: translate3d(-285px, 0, 0);
  }
}

@include keyframes(slideClosedWide) {
  from {
    //@include transform(translate3d(285px, 0px, 0px));
    transform: translate3d(0, 0, 0);
  }

  to {
    //@include transform(translate3d(0px, 0px, 0px));
    transform: translate3d(-485px, 0, 0);
  }
}

@include keyframes(showNav) {
  from {
    //opacity: 0;
    z-index: -9999;
  }

  to {
    //opacity: 1;
    z-index: 1;
  }
}

@include keyframes(hideNav) {
  from {
    //opacity: 1;
    z-index: 1;
  }

  to {
    //opacity: 0;
    z-index: -9999;
  }
}

@include keyframes(overlayFade) {
  from {
    opacity: 0;
    z-index: -9999;
  }

  to {
    opacity: 1;
    z-index: 999999;
  }
}

@include keyframes(overlayHide) {
  from {
    opacity: 1;
    z-index: 999999;
  }

  to {
    opacity: 0;
    z-index: -9999;
  }
}

html {
  position: relative;
  -webkit-overflow-scrolling: touch;
  min-width: 100%;
  height: auto;
  overflow-y: scroll !important;

  body {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    min-width: 100%;
    height: auto;
    overflow-y: scroll !important;
  }

  & .site--wrapper {
    //@include animation(0, 0.3s, linear, slideClosed);

    position: relative;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
  }

  &.loading {
    //& .site--wrapper {
    //  @include animation(0, 0, none, none);
    //}
    & .nav-primary {
      @include animation(0, 0, none, none);
    }
  }

  &.nav-open {
    & .site--wrapper {
      //@include animation(0, 0.3s, ease-out, slideOpen);
      height: 100%;
      overflow: hidden;
    }
    .nav-primary {

      @include animation(0s, 0.25 s, linear, slideOpen);
      //@media only screen and (max-width: 600px) {
      //}

      @media only screen and (min-width: 768px) {
        @include animation(0s, 0.25 s, linear, slideOpenWide);
      }

      display: block;
    }
  }
}

.nav-primary-close--wrapper {

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  .nav-primary-close-link {
    font-size: (24 / 16 + rem);
    letter-spacing: 5.68px;
    font-weight: 300;
    line-height: 1rem;
    float: right;
    display: inline-block;
  }

}

.nav-primary-list {

  .nav-main-item, .nav-drop-item {
    display: block;
    margin: 30px 0;
    display: block;
  }

  .nav-main-item {
    border: 1px solid transparent;
    @include media-breakpoint-up(lg) {
      &:hover {
        border: 1px solid map-get($theme-colors, primary);
        & > .nav-main-link {
          color: map-get($theme-colors, primary);
          text-decoration: none;
        }
      }
    }
  }

  .nav-main-link {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: (24 / 16 + rem);
    }
  }

  .nav-drop-link {
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: (20 / 16 + rem);
    }
  }

  .nav-main-link, .nav-drop-link {
    padding: 10px 20px;
    display: block;
    color: map-get($theme-colors, secondary);
    line-height: (24 / 16);
    letter-spacing: 5.68px;
    font-weight: 300;
    transition: color .25s linear;

    &:hover {
      text-decoration: none;
    }
  }

  .nav-drop {}
  .nav-drop-item {
    &:hover {
      & > .nav-drop-link {
        color: map-get($theme-colors, primary);
      }
    }
  }
  .nav-drop-link {}
}