.site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 999;
  background: #fff;
}

.social-media {
  list-style-type: none;
}

/**
 * Front Page introduction styles
 * @see page-front-page.twig
 */
.front-page-content--wrapper {
  position: relative;
}

.front-page-feature-image {
  position: relative;
  display: block;
  width: 100%;

  &::before {
    content: '';
    padding-bottom: 75%;
    width: 100%;
    display: block;

    @include media-breakpoint-up(md) {
      padding-bottom: 50%;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 45%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 1;
  }
}

.front-page-content--container {
  position: relative;
  background: #fff;
  margin-top: -60px;
  float: right;
  z-index: 10;
  transform: translate3d(5px, 0, 0);
  padding: 20px 32px;

  @include media-breakpoint-up(sm) {
    transform: translate3d(10px, 0, 0);
  }

  @include media-breakpoint-up(lg) {
    padding: 60px;
  }

  @include media-breakpoint-up(xl) {
    transform: translate3d(80px, 0, 0);
  }
}


/**
 * Projects page slider styles
 * @see page-projects.twig
 */
.projects-slide--background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(22,22,22,0.3);
    transiton: background-color 150ms ease;
  }
}

.project-slide--title {
    color: #fff;
    font-weight: 200;
    @include media-breakpoint-up(lg) {
      font-size: $h1-large;
    }
}

.projects--slider {
  .owl-item.active {
    .projects--slide {
      transform: scale(1);

      .projects-slide--background::after {
        background-color: rgba(0,0,0,0.3);
      }
    }
  }
}

.projects-slide-title--wrapper {
  position: relative;
}

.projects--slide {
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.9);
  transition: transform 300ms ease;

  .projects-slide--inner {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
  }

  @include media-breakpoint-up(sm) {
    height: 415px;
    .projects-slide-inner {
      padding: 0;
    }
  }

  @include media-breakpoint-up(md) {
    height: 500px;

    .projects-slide--inner {
      align-items: flex-end;
      justify-content: flex-start;
    }

    .project-slide--title {
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      height: 450px;
      padding-top: 15px;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 600px;

    .project-slide--title {
      height: 500px;
    }
  }

  @include media-breakpoint-up(xl) {
    height: 800px;

    .project-slide--title {
      height: 600px;
    }
  }

  .projects-slide--link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}

/**
 * Contact Page Styles
 * @see page-contact.twig
 */
.contact-info--wrapper {
  padding: 3px 0;
  border-bottom: 1px solid map-get($theme-colors, primary);

  a:hover {
    text-decoration: none;
  }
}

.contact-info-details--wrapper {
  @include media-breakpoint-up(md) {
    display: inline-flex;
  }
}

.contact-info {

  &:hover {
    color: map-get($theme-colors, primary);
  }

  .fa {
    @include media-breakpoint-up(md) {
      display: block;
      text-align: center;
      margin-bottom: 3px;
    }

    @include media-breakpoint-up(lg) {
      display: inline;
      text-align: inherit;
      margin-bottom: 0;
    }
  }
 }

/**
 * Contact Form Styles
 * @see page-contact.twig
 * && sitename.php line 151 for submit buton class / altering
 */
 .contact-form--button {
   background: map-get($theme-colors, primary);
   padding: 0.45em 1.5em;
   float: right;
   border: none;
   outline: none;
   -webkit-appearance: none;
   display: inline-block;
   cursor: pointer;
   transition: background .5s ease;

   &:hover {
     background: map-get($theme-colors, secondary);
   }

   span {
     color: #fff;
     font-size: 1rem;
     transition: color .5s ease;
   }
 }

 #gform_wrapper_1 {
   .gform_footer {
     margin-top: 0;

     @media only screen and (min-width: 641px) {
       padding-right: 16px;
     }
     &::after {
       content: '';
       display: table;
       clear: both;
     }
   }

   input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), textarea {
     border: 1px solid map-get($theme-colors, secondary);
     outline: none;
     border-radius: 0;
     -webkit-border-radius:0px;
     -webkit-appearance: none;
     padding-left: 10px;
     padding-right: 10px;
     color: map-get($theme-colors, secondary);

     &::placeholder {
       color: map-get($theme-colors, secondary);
     }
   }

   li.hidden_label textarea {
     margin-top: 12px;
   }

 }

 /**
  * Contact Postal Details Title
  */
  .postal--title {
    color: map-get($theme-colors, secondary);
    display: block;
    text-align: left;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .postal--details {
    display: block;
    text-align: left;
  }

 /**
  * Contact Social Styles
  */
  .contact-form--wrapper {
    .social-media {
      padding-left: 0;
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 0;
    }

    .social-media--item {
      line-height: 1;
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    .social-media--link {
      color: map-get($theme-colors, secondary);
      font-size: ( 24 / 16 + rem);

      &:hover {
        color: map-get($theme-colors, primary);
      }
    }
  }


/**
 * FAQs Styles
 */

.faq {

  &-count--wrapper {
    text-align: center;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
      border-bottom: none !important;
      margin-bottom: 0;
      text-align: right;
      padding-right: 10px;
    }
  }

  &-count {
    color: map-get($theme-colors, primary);
    font-size: 16px;
    font-weight: 100;
    line-height: 1;
    margin-bottom: 0;
  }

  &-title--wrapper {
  }

  &--title {
    font-size: 16px;
    line-height: (30 / 16);
    color: map-get($theme-colors, secondary);
    font-weight: bold;
    text-align: left;
  }

  &-content--wrapper {

  }

}

/**
 * Entry Meta Styles
 * @see entry-meta.twig
 */
time.updated {
  font-size: 1rem;
  font-weight: 100;
  color: map-get($theme-colors, secondary);
  margin-top: 0;
  line-height: 1;
  margin-bottom: 10px;
}


/**
 * Testimonials Styles
 * @see page-testimonials.twig
 */
.testimonial-side-title-post--wrapper {
  & > .container {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -3rem;
      left: 0;
      right: 0;
      height: 1px;
      background: #D9D9D9;
    }
  }

  &.Text {
    .testimonial-content--wrapper {
      font-size: (20 / 16 + rem);
      line-height: 1.5;
      font-style: italic;
      & > :last-child {
        margin-bottom: 0;
      }

      p {
        font-size: (20 / 16 + rem);
        line-height: 1.5;
        font-style: italic;
        color: #636466;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .single-side-post--title {
      padding-bottom: 1.5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    &.Text {
      .testimonial-content--wrapper {
        font-size: (36 / 16 + rem);
        line-height: (55 / 36);

        p {
          font-size: (36 / 16 + rem);
          line-height: (55 / 36);
        }
      }
    }
  }
}

