html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and ( min-device-pixel-ratio: 1.25), only screen and ( min-resolution: 200dpi), only screen and ( min-resolution: 1.25dppx) {
  html {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

//** Responsive Video Embeds **//
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

* {
  box-sizing: border-box;
}

input, textarea {
  -webkit-appearance: none;
}

html {
  .owl-theme .owl-dots .owl-dot {
    span {
      background: #F7DBCE;
    }

    &.active span {
      background: map-get($theme-colors, primary);
      height: 10px;
      width: 10px;
    }
  }

  body:not(.home) {
    .owl-theme .owl-nav.disabled+.owl-dots {
      margin-top: 20px;
    }
  }
}

.related-posts--title {
  color: map-get($theme-colors, secondary);
}

ul, ol {
  &.double {
    column-count: 2;
    padding-left: 0;

    li {
      list-style-type: none;
    }
  }
}

.scvl-text-grey {
  color: map-get($theme-colors, secondary);
}