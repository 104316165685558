/**
 * Front page styles for header
 */
body.page-template-template-front-page {
  .site-header {
    background: transparent;
    transition: background 300ms ease-in-out;

    &.nav-updates {
      background: #fff;

      .header-right--wrapper {
        .social-media .social-media--item {
          .social-media--link {
            color: map-get($theme-colors, secondary);
          }
        }
      }

      .header-menu--line {
        background-color: map-get($theme-colors, secondary);

      }

      .header-menu--title {
        color: map-get($theme-colors, secondary);
      }
    }
  }
  .header-right--wrapper {
    .social-media .social-media--item {
      .social-media--link {
        color: $white;
        transition: color 300ms ease-in-out;
      }
    }
  }

  .header-menu--line {
    background-color: $white;
    transition: background-color 300ms ease-in-out;
  }

  .header-menu--title {
    color: $white;
    transition: color 300ms ease-in-out;
  }
}

/**
 * General Page styles for header
 */
.header-right--wrapper {
  text-align: center;

  .social-media {
    display: inline-flex;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    padding: 5px 0;

    @include media-breakpoint-up(md) {
      margin-right: 50px;
    }
  }

  .social-media--item {
    margin: 0 8px;

    .social-media--link {
      color: map-get($theme-colors, secondary);

      &:hover,
      &:active,
      &:focus {
        color: map-get($theme-colors, primary);
      }
    }
  }

  .header-menu--wrapper {
    text-align: center;
    cursor: pointer;

    &:hover {
      .header-menu--title {
        color: map-get($theme-colors, primary);
      }

      .header-menu--line {
        background-color: map-get($theme-colors, primary);
      }

    }
  }
}

.nav-primary ul
{
  list-style-type: none;
}

/**
 * @see header.twig
 */
.header-menu--title {
  font-size: (18 / 16 + rem);
  line-height: (18 / 16);
  font-weight: bold;
  letter-spacing: 4.26px;
  color: map-get($theme-colors, secondary);
}

.header-menu--line {
  display: block;
  margin: 5px 0;
  height: 2px;
  background-color: map-get($theme-colors, secondary);

  &.first {
    width: 24px;
  }

  &.second {
    width: 20px;
  }

  &.third {
    width: 17px;
  }
}

.nav-primary-close-link {
  &:hover {
    text-decoration: none;
  }
 }

.brand--logo {
  width: 157px;
  height: auto;
  max-width: 100%;
}


/**
 * Front page slider styles.
 * @see header.twig
 */
.front-page-slider.owl-carousel.owl-theme {

   .owl-dots {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-top: 0;

    .owl-dot {
      span {
        background: #fff;
        height: 8px;
        width: 8px;
      }

      &.active {
        span {
          height: 10px;
          width: 10px;
          background: map-get($theme-colors, primary);
        }
      }
    }
  }

  /**
   * Single slide styles
   */
  .front-page-slide {
    height: 100vh;
    width: 100%;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
      z-index: 0;
    }

    .slide-text--wrapper {
      width: 100%;
    }
  }

  .front-page-slide {
    position: relative;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      align-items: flex-end;
    }
  }

  .slide-text--container {
    position: relative;
    z-index: 1;
  }

  .slide-text {
    color: $white;
    text-align: center;
    font-weight: 100;

    @include media-breakpoint-up(sm) {
      font-size: (64 / 16 + rem);
    }

    @include media-breakpoint-up(md) {
      text-align: left;
      height: 70vh;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      padding-top: 70px;
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 90px;
    }
  }
}

.page-introduction--wrapper {
  margin-top: 25px;
  margin-bottom: 15px;
}

body.single.single-post.post-template-default {
  .page-header {
    .page-introduiction--wrapper {
      & > :last-child {
        margin-bottom: 0;
      }
    }

    padding-bottom: 60px !important;
  }
}