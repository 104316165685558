.block.page-builder
{
  padding: $page-builder-block-spacing 0;
}

.feature-project-block--wrapper {

  & > .container {
    .project-feature--image {
      @include media-breakpoint-up(md) {
        &::before {
          padding-bottom: 56.25%;
        }
      }
    }
  }

  .project-feature--container {
    position: relative;
    box-shadow: 4px 4px 10px rgba(0,0,0,0.4);
  }

  .project-feature--image {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 65%;
      @include media-breakpoint-up(sm) {
        padding-bottom: 45%;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(0,0,0,0.1);
      z-index: 0;
    }
  }

  .project-block--link {
    position: absolute;
    bottom: 25px;
    right: 25px;
    padding: 0.7em 2em;
    border: 1px solid #fff;
    text-align: center;
    color: #fff;
    font-size: 1.25rem;
    z-index: 10;
    font-weight: 300;
    background: rgba(0,0,0,0.1);

    &:hover {
      background: rgba(0,0,0,0.4);
      text-decoration: none;
    }
  }
}

/**
 * Page Builder Banner Image Styles
 */
.page-builder--banner-image-layout {
  .pb-banner-image {
    position: relative;
    width: 100%;

    &.full-width {
      &::after {
        content: '';
        display: block;
        position: relative;
        padding-bottom: 75%;

        @include media-breakpoint-up(sm) {
          padding-bottom: 65%;
        }

        @include media-breakpoint-up(lg) {
          padding-bottom: 56.25%;
        }

        @media only screen and (min-width: 1600px) {
          padding-bottom: 45%;
        }
      }
    }

    &.standard {
      &::after {
        content: '';
        display: block;
        padding-bottom: 65%;

        @include media-breakpoint-up(md) {
          padding-bottom: 60%;
        }

        @include media-breakpoint-up(lg) {
          padding-bottom: 55%;
        }
      }
    }
  }
}