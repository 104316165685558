/**
 * Footer styles
 * @see footer.twig
 */
.footer-cta--wrapper {
  padding: 80px 0;
}

.footer-main--wrapper {
  background-color: map-get($theme-colors, tertiary);
}

.footer-cta--title {
  color: map-get($theme-colors, secondary);
  font-weight: 100;
}

/**
 * Footer Navigation Styles
 * @see blocks/nav-footer.twig
 */
.footer-navigation {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(md) {
    flex-flow: row wrap;
  }

  @include media-breakpoint-up(xl) {
    justify-content: flex-start;
  }

  .nav-main-item {
    list-style-type: none;

    @include media-breakpoint-up(md) {
      &:first-of-type {
        .nav-main-link {
          padding-left: 0 !important;
        }
      }
    }

    .nav-main-link {
      display: block;
      padding: 0.5em 0.75em;
      text-align: center;
      color: map-get($theme-colors, secondary);
      font-size: (18 / 16 + rem);
      font-weight: 100;
    }
  }
}

.footer-social--wrapper {

  .social-media {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 15px 0;
    padding-left: 0;

    .social-media--item {
      margin: 0 10px;

      .social-media--link {
        color: map-get($theme-colors, secondary);
        font-size: (20 / 16 + rem);

        &:hover {
          color: map-get($theme-colors, primary);
        }
      }

    }

  }
}

.footer-post--wrapper {
  a {
    color: inherit;

    &:hover {
      color: map-get($theme-colors, primary);
      text-decoration: none;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.footer-post--wrapper {
  p {
    font-size: 13.5px;
  }
}