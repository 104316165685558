body
{
  font-size: 0.875rem; // 14px
  transition: font-size 150ms;

  @include media-breakpoint-up(sm) // min-width: 576px
  {
    font-size: 15px; // 15px
  }

  @include media-breakpoint-up(md) // min-width: 768px
  {
    font-size: 16px; // 16px
  }
}

h1 {  margin-bottom: $h1-margin-bottom;  }
h2 {  margin-bottom: $h2-margin-bottom;  }
h3 {  margin-bottom: $h3-margin-bottom;  }
h4 {  margin-bottom: $h4-margin-bottom;  }
h5 {  margin-bottom: $h5-margin-bottom;  }
h6 {  margin-bottom: $h6-margin-bottom;  }

p, span
{
  color: $paragraphs-color;
}

p {
  font-size: 16px;
  font-weight: normal;
  margin-top: $paragraphs-margin-top;
  margin-bottom: $paragraphs-margin-bottom;
  line-height: $paragraphs-line-height;

  span {
    font-size: inherit;
    font-weight: inherit;
  }
}

