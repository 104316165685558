.architecture-block-image {

  &.single-image {
    display: block;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 75%;

      @include media-breakpoint-up(md) {
        padding-bottom: 56.25%;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 45%;
      }
    }
  }

  &.double-image {
    position: relative;
    height: 250px;

    @include media-breakpoint-up(sm) {
      height: 350px;
    }

    @include media-breakpoint-up(md) {
      height: 450px;
    }

    @include media-breakpoint-up(lg) {
      height: 500px;
    }

    @include media-breakpoint-up(xl) {
      height: 550px;
    }

    @media only screen and (min-width: 1600px) {
      height: 650px;
    }
  }

  &.triple-image {
    position: relative;
    display: block;

    &::before {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: 100%;

      @include media-breakpoint-up(sm) {
        padding-bottom: 115%;
      }
    }
  }
}