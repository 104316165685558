.btn.btn-primary {
  display: inline-block;
  padding: .75em 2.5em;
  font-size: (20 / 16 + rem);
  text-align: center;
  color: #000;
  border: 1px solid map-get($theme-colors, secondary);
  background: transparent;
  border-radius: 0;
  transition: background 50ms linear, color 50ms linear, border 50ms linear;

  &.footer-cta--button {
    &:hover {
      background: map-get($theme-colors, primary);
      color: #fff;
      border: 1px solid transparent;
    }
  }

  &:hover {
    background: rgba(0,0,0,0.1);
  }
}