.single-project-header--wrapper {
  position: relative;
}

.single-project-title--wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  @include media-breakpoint-up(md) {
    align-items: flex-end;
    padding-bottom: 100px;

    .single-project--title {
      margin: 0;
      text-align: left;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      height: 300px;
    }
  }

  @include media-breakpoint-up(lg) {
    .single-project--title {
      font-size: (64 / 16 + rem);
      height: 350px;
    }
  }

  @include media-breakpoint-up(xl) {
    .single-project--title {
      font-size: (64 / 16 + rem);
      height: 400px;
    }
  }
}

.single-project--title {
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  font-weight: 100;
}

.single-project--background {
  position: relative;
  &::after {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
  }
}

/**
 * Single News Posts
 */

.single-news-post--wrapper {
  & > .container {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -3rem;
      left: 0;
      right: 0;
      height: 1px;
      background: #D9D9D9;
    }
  }
}

.single-side-title-post--wrapper.Text {
  @include media-breakpoint-up(md) {
    .testimonial-content--wrapper {
      border-left: 1px solid #979797;
    }
  }
}

.single-news-post-title--wrapper, .single-side-post-title--wrapper {

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .single-news-post--title, .single-side-post--title {
      writing-mode: vertical-rl;
      height: 250px;
      margin: 0;
      position: relative;
      transform: rotate(-180deg);
    }
  }

  @include media-breakpoint-up(lg) {
    .single-news-post--title, .single-side-post--title {
      height: 270px;
    }
  }

  @include media-breakpoint-up(xl) {
    .single-news-post--title, .single-side-post--title {
      height: 360px;
    }
  }

}

.single-news-post--title, .single-side-post--title {
  & > a {
    &:hover {
      text-decoration: none;
    }
  }
}

//.single-news-post--title {
//  @include media-breakpoint-up(md) {
//    transform: rotate(-90deg) translateX(100%);
//    transform-origin: bottom right;
//    padding-bottom: 30px;
//  }
//
//  & a {
//    &:hover {
//      text-decoration: none;
//    }
//  }
//}

.single-project-introduction-container {
  position: relative;
  z-index: 10;
  background: #fff;

  @include media-breakpoint-up(md) {
    margin-top: -80px;
  }
}

/**
 * Related posts Gallery
 */

.related-posts--slider {
  .owl-stage {
    .owl-item {
      &.active {
        .related-posts--slide {
          margin: 0 !important;
          opacity: 1;

          .slide-background--image {
            &::after {
              background: rgba(0,0,0,0.2);
            }
          }
        }
      }
    }
  }

  .related-posts--slide {
    position: relative;
    height: 350px;
    margin: 2% 4%;
    opacity: 0.75;
    transition: margin 1s ease, opacity 1s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      height: 600px;
      align-items: flex-end;
      justify-content: flex-start;
      padding-left: 40px;
      padding-bottom: 15px;
    }



    .slide-background--image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.65);
        z-index: 0;
        transition: background 0.3s linear;
      }
    }

    .rotation-outer--wrapper {
      position: relative;
      z-index: 10;
    }

    .slide-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .related-posts-slide-title--wrapper {
      & > h2 {
        color: #fff;
        text-align: center;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
          font-size: (64 / 16 + rem);
          text-align: left
        }
      }
    }
  }
}


/**
 * Pagination Styles
 */
.tool-pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 50px 0;

  .prev, .next {
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }

  .pages {
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    margin: 0 10px;

    li {
      list-style-type: none;
      margin: 0 7px;

      .page-number {
        &:not(.current) {
          color: map-get($theme-colors, secondary);
          font-weight: 100;
        }
        &.current {
          font-weight: bold;
          color: map-get($theme-colors, primary);
        }
      }
    }

  }
}

.testimonial-content--wrapper {
  @include media-breakpoint-up(md) {
    padding: 60px 0;
  }
}

.single-post-back--wrapper {
  .back--link {
    padding: 1em 2em;
    border: 1px solid #D9D9D9;
    font-size: 1rem;
    transition: background .5s ease;
    color: map-get($theme-colors, secondary);
    display: inline-block;

    &:hover {
      color: map-get($theme-colors, primary);
      text-decoration: none;
    }
  }
}